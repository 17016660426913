import { Button, Modal, Popconfirm, Typography } from "antd";
import { useEffect, useState } from "react";
import { PlusOutlined, TrashOutlined } from "@ant-design/icons";
import MediaUpload from "./MediaUpload";
import { BiTrash } from "react-icons/bi";
import CAxios from "../../Utils/CAxios";

const MediaList = ({ medias, id }) => {
  const [item, setItem] = useState("");
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    console.log(process.env.REACT_APP_API_URL + "/public/PJ/");
  }, []);
  const deleteMedia = async (id) => {
    const { data } = await CAxios.post("/api/media/remove", { id });
    if (data.success.media) {
      window.location.reload();
    }
  };
  return (
    <div>
      <Button type="link" size="large" onClick={() => setVisible(true)} icon={<PlusOutlined />}></Button>
      <MediaUpload display={visible} id={id} entity={"quote"} setVisible={(e) => setVisible(e)} />

      {medias.map((el) => (
        <p>
          <Typography.Link onClick={() => setItem(el.name)}>{el.name}</Typography.Link>

          <Popconfirm
            title={`Voulez vous vraiment supprimer cet pièce jointe?`}
            onConfirm={() => deleteMedia(el.id)}
            okText="Oui"
            cancelText="Non"
            placement="left"
          >
            <Button type="link" icon={<BiTrash />}></Button>
          </Popconfirm>
        </p>
      ))}
      <Modal visible={item !== ""} style={{ minHeight: "500px" }} className=" modal-large" onCancel={() => setItem("")}>
        <div style={{ alignItems: "center" }}>
          <iframe style={{ width: "100%", height: "100%", minHeight: "500px" }} src={process.env.REACT_APP_API_URL + "/PJ/" + item} />
        </div>
      </Modal>
    </div>
  );
};

export default MediaList;
