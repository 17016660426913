import { Modal } from "antd";
import { useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import CAxios from "../../Utils/CAxios";

const MediaUpload = ({ id, entity, display, setVisible }) => {
  useEffect(() => {
    console.log({ display });
  }, [display]);
  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "50px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({ multiple: false });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const files = acceptedFiles.map((file) => <li key={file.path}>{file.path}</li>);
  const onUpload = async () => {
    var bodyFormData = new FormData();
    bodyFormData.append("file", acceptedFiles[0]);
    bodyFormData.append("id", id);

    const { data } = await CAxios.post("/api/media/add", bodyFormData);
    setVisible(false);
    if (data.success.media) {
      window.location.reload();
    }
  };
  return (
    <Modal
      className="smtv-modal modal-large"
      visible={display}
      okText="Uploader le(s) fichier(s)"
      onOk={() => onUpload()}
      onCancel={() => setVisible(false)}
    >
      <section className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>Faites glisser et déposez quelques fichiers ici, ou cliquez pour sélectionner des fichiers</p>
        </div>
        <aside style={{ marginTop: "20px" }}>
          <h4>Fichiers</h4>
          <ul>{files}</ul>
        </aside>
      </section>
    </Modal>
  );
};

export default MediaUpload;
